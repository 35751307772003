export default function Merch() {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 md:gap-x-10 gap-y-5 w-full">
			<HoverLink
				url={"/faq"}
				imageURL="/images/book.jpg"
				alt="Cover of my book"
				label="My book!"
			/>
			<HoverLink
				url={"/faq"}
				imageURL="/images/designjournal.jpg"
				alt="Cover of my workbook"
				label="My workbook!"
			/>
			<HoverLink
				url={"https://zoehong-shop.fourthwall.com"}
				imageURL="/images/mugs.jpg"
				alt="My coffee mugs"
				label="My mugs!"
				target="shopping"
			/>
			<HoverLink
				url={"https://etchedstonepaper.com/products/zoehongsketchbook"}
				imageURL="/images/sketchbook.jpg"
				alt="Cover of my sketchbook"
				label="My sketchbook!"
				target="shopping"
			/>
		</div>
	);
}

function HoverLink({
	url,
	imageURL,
	alt,
	label,
	target,
}: {
	url: string;
	imageURL: string;
	alt: string;
	label: string;
	target?: string;
}) {
	return (
		<a
			href={url}
			title={label}
			target={target}
			className="block"
			rel="noreferrer"
		>
			<div className="aspect-video">
				<img
					src={imageURL}
					alt={alt}
					className="object-cover rounded-xs shadow-xs"
				/>
			</div>
			<div className="my-2 text-xl text-center font-semibold">{label}</div>
		</a>
	);
}
