import Merch from "../components/MainLinks";
import { MiscLinks } from "../components/MiscLinks";

export default function () {
	return (
		<div className="mx-auto text-center">
			<Merch />
			<div className="hidden md:block my-8" />
			<MiscLinks />
		</div>
	);
}
