export function MiscLinks() {
	return (
		<div className="grid grid-cols-2 md:grid-cols-4 gap-x-4 md:gap-x-10 gap-y-5 w-full">
			<MiscLink
				alt="YouTube"
				imageURL="/images/youtube.jpg"
				label="Watch my videos"
				url={"https://www.youtube.com/zoehongteaches"}
			/>
			<MiscLink
				alt="Patreon"
				imageURL="/images/patreon.jpg"
				label="Take my classes"
				url={"https://www.patreon.com/zoehong"}
			/>
			<MiscLink
				alt="My book recommendations"
				imageURL="/images/bookrecs.jpg"
				label="Book recommendations"
				url={
					"https://www.amazon.com/shop/zoehong/list/3MV9ITJSXC48Q?ref_=aip_sf_list_spv_ofs_mixed_d"
				}
			/>
			<MiscLink
				alt="My tool recommendations"
				imageURL="/images/toolrecs.jpg"
				label="Tool recommendations"
				url={"https://www.amazon.com/shop/zoehong"}
			/>

			<div className="hidden md:block" />
			<MiscLink
				alt="Consult"
				imageURL="/images/consults.jpg"
				label="Book a private consult"
				url={"https://calendly.com/zoe-hong"}
			/>
			<MiscLink
				alt="Shopify"
				imageURL="/images/shopify.jpg"
				label="My shop"
				url={"https://shop.zoehong.com/"}
			/>
			<div className="hidden md:block" />
		</div>
	);
}

function MiscLink({
	alt,
	imageURL,
	label,
	url,
}: {
	alt: string;
	imageURL: string;
	label: string;
	url: string;
}) {
	return (
		<a href={url} title={label} target={alt} className="block">
			<div className="flex flex-col gap-4">
				<img
					src={imageURL}
					alt={alt}
					className="object-cover rounded-xs shadow-xs"
				/>
			</div>
			<div className="my-2 text-lg text-center font-semibold">{label}</div>
		</a>
	);
}
